import { Routes, RouterModule } from "@angular/router";
import { ModuleWithProviders } from "@angular/core";
import { LoginComponent } from "./front/elements/login/main/login.component";
import { CreatePasswordComponent } from "./front/elements/login/create-password/create-password.component";
import { RecoverPasswordComponent } from './front/elements/login/recover-password/recover-password.component';
import { AuthGuard } from './front/elements/login/auth.guard';
import { HeaderComponent } from "./front/elements/header/header.component";
import { SearchTicketsComponent } from "./front/elements/entities/search-tickets/search-tickets.component";
import { CashFlowComponent } from "./front/elements/entities/cash-flow/cash-flow.component";
import { SellTicketsComponent } from "./front/elements/entities/sell-tickets/sell-tickets.component";
import { ChooseTravelComponent } from "./front/elements/entities/sell-tickets/choose-travel/choose-travel.component";
import { ChooseSeatsComponent } from "./front/elements/entities/sell-tickets/choose-seats/choose-seats.component";
import { FillDataComponent } from "./front/elements/entities/sell-tickets/fill-data/fill-data.component";
import { PayTicketComponent } from "./front/elements/entities/sell-tickets/pay-ticket/pay-ticket.component";
import { InitialComponent } from "./front/elements/initial/initial.component";
import { InitialGuard } from "./front/elements/initial/initial.guard";
import { BlockedTerminalComponent } from "./front/elements/blocked-terminal/blocked-terminal.component";

const appRoutes: Routes = [
  { path: '', redirectTo: 'initial', pathMatch: 'full' },
  { path: 'login', component: LoginComponent,
    canActivateChild: [InitialGuard] },
  { path: 'initial', component: InitialComponent },
  { path: 'blockedTerminal', component: BlockedTerminalComponent },
  { path: 'newPassword', component: CreatePasswordComponent},
  { path: 'recoverPassword', component: RecoverPasswordComponent},
  { path: 'header', component: HeaderComponent,
    children: [
      { path: 'sellTickets', component: SellTicketsComponent,
        children: [
          { path: 'chooseTravel', component: ChooseTravelComponent},
          { path: 'chooseSeats', component: ChooseSeatsComponent},
          { path: 'fillData', component: FillDataComponent},
          { path: 'payTicket', component: PayTicketComponent}
        ]
      },
      { path: 'searchTickets', component: SearchTicketsComponent},
      { path: 'cashFlow', component: CashFlowComponent }
    ],
    canActivateChild: [AuthGuard]
  }
];

export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes, { useHash: true });
